import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import InvoicingSection from '@/app/invoicing/InvoicingSection';
import { IcontactsProps } from '@/types';
import { subscribe } from '@/http-request/contact-request';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';
import ContactStyled from '@/components/styled/ContactStyled';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';

// eslint-disable-next-line no-shadow
enum Form {
  SUCCESS,
  FAILED,
  NORMAL,
}

const OfficeCards = ({ office }) => {
  const { title, image, address, email, phoneNumber, googleMapLink } = office;
  return (
    <div className="office-info-ph">
      <GatsbyImage image={getImage(image.gatsbyImageData)} alt="" />

      <h3>{title}</h3>

      <div className="office-info-fi">
        <ContentfulRawToComponent raw={address.raw} />

        <p className="body-1">
          {email}
          <br />
          {phoneNumber}
        </p>
      </div>

      <a
        className="link"
        href={googleMapLink}
        target="_blank"
        rel="noreferrer"
        aria-label="Google Map Link"
      >
        Open in Google Maps
      </a>
    </div>
  );
};

const contacts: React.FC<IcontactsProps> = ({ data }) => {
  const [formState, setFormState] = useState<Form>(Form.NORMAL);
  const [formMsg, setFormMsg] = useState(``);
  const contactPageMeta = data.contentfulBlock?.meta ?? {};
  const offices = data.allContentfulOffices.nodes;

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    if (formState !== Form.SUCCESS) {
      Promise.all([subscribe(form)])
        .then((res) => {
          if (
            res[0].data?.data?.statusCode &&
            res[0]?.data?.data?.statusCode === 400
          ) {
            setFormState(Form.FAILED);
          } else {
            setFormState(Form.SUCCESS);
            e.target.reset();
          }
        })
        .catch(() => {
          setFormState(Form.FAILED);
        });
    }
  };

  useEffect(() => {
    if (formState === Form.SUCCESS) {
      // should display a message that the form was sent successfully
      setFormMsg(`Message sent successfully!`);
      setTimeout(() => {
        setFormState(Form.NORMAL);
      }, 5000);
    }

    if (formState === Form.NORMAL) {
      // should display a message that the form was sent successfully
      setFormMsg(`We’ll get back to you within a day.`);
    }

    if (formState === Form.FAILED) {
      // should display a message that the form was sent successfully
      setFormMsg(`Please check the fields are properly filled.`);
      setTimeout(() => {
        setFormState(Form.NORMAL);
      }, 5000);
    }
    // should display a message that the form failed to send.
  }, [formState]);

  return (
    <ContactStyled>
      {usePageMeta(contactPageMeta)}

      <div className="content contact-us-page">
        <div className="container">
          <div className="page-title h1-title">
            <h1>Contact</h1>
          </div>

          <div className="main-content">
            <Reveal className="intro">
              <h1>Hi, how can we help?</h1>

              <p>
                Having a foothold on <mark>two continents</mark>, we are&nbsp;
                <mark>one team</mark> delivering award-winning digital commerce
                solutions for <mark>international clients</mark>.
              </p>

              <p>
                Fill out the form below or book an intro with Mikko straight
                away. Either way, let’s get things rolling!
              </p>

              <div className="intro-footer">
                <div id="book-a-meet">
                  <img src="/assets/images/contact/mutte-1.jpg" alt="" />
                  <a
                    href="https://calendly.com/mikko-mantila/introduction"
                    className="btn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Book a 30 min intro
                  </a>
                </div>

                <a href="#offices" className="link">
                  Offices
                </a>
              </div>
            </Reveal>

            <Reveal>
              <img
                src="/assets/images/contact/contact-2.jpg"
                className="contact-img"
                alt=""
              />
            </Reveal>
          </div>
        </div>
      </div>

      <Reveal className="contact-form">
        <form
          className="container"
          onSubmit={onSubmitHandler}
          id="contact-form"
          method="post"
          data-form-type="contact"
        >
          <h2>Drop us a line</h2>

          <fieldset>
            <div className="info-row">
              <label htmlFor="name">
                Name
                <input id="name" type="text" name="name" required />
              </label>
              <label htmlFor="phone-email">
                Email or Phone
                <input id="phone-email" type="email" name="email" required />
              </label>
            </div>

            <label htmlFor="message">
              How can we help you?
              <textarea
                name="message"
                id="message"
                cols={30}
                rows={10}
                required
              />
            </label>

            <div className="row">
              <div className="col-2 col-t-1 col-m-2 submit-button">
                <button type="submit" className="wide send-button">
                  SEND
                </button>
              </div>

              <div className="col-2 col-t-1 col-m-2 form-text">
                <p>{formMsg}</p>
              </div>
            </div>
          </fieldset>
        </form>
      </Reveal>

      <Reveal id="offices" className="office-section">
        <div className="container">
          <h2>Offices</h2>

          <div className="offices">
            {offices.map((office, index) => (
              <OfficeCards key={index} office={office} />
            ))}
          </div>
        </div>
      </Reveal>

      <InvoicingSection />
    </ContactStyled>
  );
};

export const query = graphql`
  query admins {
    allContentfulStaff(
      filter: { roles: { eq: "Contact person" } }
      sort: { fields: id }
    ) {
      nodes {
        id
        countryCode
        linkedInProfile
        calendlyUrl
        firstName
        lastName
        phoneNumber
        emailAddress
        designation
        photo {
          gatsbyImageData
        }
      }
    }
    contentfulBlock(section: { eq: "contact_heading" }) {
      slug
      title
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
    }
    allContentfulOffices(sort: { fields: order, order: ASC }) {
      nodes {
        address {
          raw
        }
        googleMapLink
        email
        title
        phoneNumber
        image {
          id
          gatsbyImageData
          title
        }
      }
    }
  }
`;

export default contacts;
